define('prepRunExtractionStop',[
    'module',
    'dialogFormView',
    'template!prepRunExtractionStop',
    'savingBehavior',
    'underscore',
    'jquery',
    'autocompleteView',
    'backbone'
], function (
    module,
    DialogFormView,
    ExtractionTpl,
    SavingBehavior,
    _,
    $,
    AutocompleteView,
    Backbone
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: ExtractionTpl,

        regions: {
            selectRespUser: '.js-select-respUser-region',
            selectExtractor: '.js-select-extractor-region'
        },

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'hide',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        fieldsToValidate: [
            {name: 'refLmbExtractor', type: 'required'},
            {name: 'endUserpid', type: 'required'}
        ],

        initialize: function (options) {
            this.extractionDetails = options.runModel.get('extractionDetails');
            this.model = new Backbone.Model();
            this.model.set('refLmbExtractor', null);
            this.model.set('endUserpid', options.currentUser);
            this.model.set('comment', null);
            if (this.options.runModel.get('extractionDetails').get('refExtractionMethod').get('manual')) {
                this.fieldsToValidate = [
                    {name: 'endUserpid', type: 'required'}
                ];
            }
        },

        onRespUserChange: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
        },

        serializeData: function () {
            return {
                isManual: this.options.runModel.get('extractionDetails').get('refExtractionMethod').get('manual'),
                comment: this.model.get('comment')
            };
        },
        onRender: function () {
            if (!this.options.runModel.get('extractionDetails').get('refExtractionMethod').get('manual')) {
                var LmbExtractorService = require('services/caccounts/lmbextractors');
                this.extractorView = new AutocompleteView(
                    this._getAutocompleteOptionObject(LmbExtractorService.getAutocompleteParamFromExtractionMethodSecId({
                        modelProperty: 'refLmbExtractor'
                    }, this.options.runModel.get('extractionDetails').get('refExtractionMethod').get('secId')))
                );
                this.getRegion('selectExtractor').show(this.extractorView);
            }
            var UserpidService = require('services/caccounts/userpids');
            this.respUserView = new AutocompleteView(
                this._getAutocompleteOptionObject(UserpidService.getAutocompleteParam({
                    modelProperty: 'endUserpid',
                    valueProperty: 'nickName',
                    queryProperty: 'nickName',
                    valueKey: 'nickName',
                    callBackOnChange: this.onRespUserChange
                }))
            );
            this.getRegion('selectRespUser').show(this.respUserView);
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            var fieldsToValidate = this.fieldsToValidate;
            if (this.options.runModel.get('extractionDetails').get('refExtractionMethod').get('manual')) {
                fieldsToValidate = _.filter(fieldsToValidate, function (field) {
                    return field.name !== 'refLmbExtractor';
                });
            }
            if (this.validate(fieldsToValidate)) {
                $('.js-global-loader').show();
                this.options.runModel.stopExtraction(this.model)
                    .done(_.bind(this.hide, this))
                    .fail(_.bind(function () {
                        this.options.runModel.fetch()
                            .done(_.bind(function () {
                                // this.box.modal('hide');
                                this.hide();
                            }, this));
                    }, this))
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        hide: function () {
            this.trigger('run:prep:extraction:update:buttons');
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        }
    });
});
